import { useAtomValue } from 'jotai'
import { PV_THRESHOLD, URL_PATH } from '@/consts'
import useAuth from '@/hooks/useAuth'
import { viewedBoardIdsAtom } from '@/stores'
import { BottomSheetContent } from '@/v1/Modal/BottomSheet/Content'
import LoginContent from '@/v1/Modal/BottomSheet/Content/LoginContent'
import { useModal } from '../useModal'

interface ThresholdProps {
  id: string
  onSuccess: () => void
  skip?: boolean
}

/**
 * PV_THRESHOLD를 초과하여 게시글을 클릭했을 때 로그인 바텀 시트를 띄우는 커스텀 훅
 * @returns {handlePVThreshold} - PV_THRESHOLD를 초과하여 게시글을 클릭했을 때 로그인 바텀 시트를 띄우는 함수
 */
const usePVThreshold = () => {
  const { isLoggedIn } = useAuth()
  const { bottomSheetModal } = useModal()
  const viewedBoardIds = useAtomValue(viewedBoardIdsAtom)

  /**
   * PV_THRESHOLD를 초과하여 게시글을 클릭했을 때 로그인 바텀 시트를 띄우는 함수
   * @param id - 게시글 id
   * @param onSuccess - PV_THRESHOLD를 초과하지 않았을 때 실행할 콜백 함수
   * @param skip - 로그인 바텀 시트를 패스하고 onSuccess를 실행할지에 대한 여부
   */
  const handlePVThreshold = ({
    id,
    onSuccess,
    skip = false,
  }: ThresholdProps) => {
    // option으로 skip을 true로 준 게시글의 경우 패스
    if (skip) return onSuccess()
    // 로그인 한 유저는 패스
    if (isLoggedIn) return onSuccess()
    // 이미 본 게시글은 패스
    if (viewedBoardIds.includes(id)) return onSuccess()
    // 게시글 클릭 횟수가 PV_THRESHOLD 이하라면 패스
    if (viewedBoardIds.length < PV_THRESHOLD) return onSuccess()

    // 로그인 바텀 시트 띄우기
    bottomSheetModal.show({
      header: {
        size: 'xs',
      },
      content: (
        <LoginContent
          description={<BottomSheetContent />}
          redirectPath={`${URL_PATH.Boards}${id}/`}
        />
      ),
    })
  }

  return { handlePVThreshold }
}

export default usePVThreshold
