import { Children, useContext } from 'react'
import type { PropsWithChildren } from 'react'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import {
  faButtonLayoutGapStyles,
  faButtonLayoutStyle,
  faButtonStyleByVariant,
  faButtonPositionStyle,
} from '@/v1/FAButton/styles'
import type { FAButtonProps } from '@/v1/FAButton/types'
import { FAButtonGroupContext } from '@/v1/FAButtonGroup'

const FAButton = ({
  type = 'button',
  variant = 'regular',
  size = 'large',
  color = 'primary',
  rounded: isRounded = false,
  disabled: isDisabled,
  position = 'bottom-right',
  className,
  children,
  ...restProps
}: PropsWithChildren<FAButtonProps>) => {
  const groupContext = useContext(FAButtonGroupContext)
  const isInGroup = Boolean(groupContext)

  const faButtonLayoutGapStyle =
    faButtonLayoutGapStyles[Children.count(children) > 2 ? 1 : 0]

  const faButtonStyle = isInGroup
    ? groupContext?.classNames
    : twMerge(
        clsx(
          faButtonLayoutStyle,
          faButtonLayoutGapStyle,
          faButtonStyleByVariant[variant].size[size],
          faButtonStyleByVariant[variant].color[color],
          {
            [faButtonStyleByVariant[variant].rounded]:
              variant === 'extended' && isRounded,
          },
          faButtonPositionStyle[position],
        ),
        className,
      )

  return (
    <button
      type={type}
      className={faButtonStyle}
      disabled={isDisabled}
      {...restProps}
    >
      {children}
    </button>
  )
}

export default FAButton
