import { FAB_BOTTOM_POSITION } from '@/consts'
import { isWebview } from '@/utils'
import type { FAButtonGroupStyle } from '@/v1/FAButtonGroup/types'

const isApp = isWebview()

export const faButtonGroupLayoutStyle =
  'fixed flex justify-evenly items-center flex-nowrap shadow-[0_6px_16px_rgba(40,45,50,0.2)]'

export const faButtonGroupStyle: FAButtonGroupStyle = {
  size: {
    large:
      'min-w-[180px] w-full max-w-[240px] h-[56px] rounded-[16px] text-[16px] [&_svg]:w-[24px] [&_svg]:h-[20px]',
    medium:
      'min-w-[180px] w-full max-w-[240px] h-[44px] rounded-[12px] text-[16px] [&_svg]:w-[20px] [&_svg]:h-[20px]',
  },
  color: {
    primary: 'text-white, bg-lavendar-500',
    secondary: 'text-grey-900 bg-white',
  },
  divider: {
    primary: 'w-[1px] h-[16px] bg-lavendar-300',
    secondary: 'w-[1px] h-[16px] bg-grey-300',
  },
  rounded: 'rounded-[28px]',
}

export const faButtonGroupItemStyle = {
  layout: 'flex justify-center items-center flex-wrap cursor-pointer gap-[4px]',
  color: {
    primary:
      'text-white hover:text-lavendar-200 [&_svg]:fill-white [&_svg]:hover:fill-lavendar-200',
    secondary:
      'text-grey-900 hover:text-lavendar-500 [&_svg]:fill-grey-900 [&_svg]:hover:fill-lavendar-500',
  },
}

export const faButtonGroupPositionStyle = {
  'bottom-right': `${
    isApp ? FAB_BOTTOM_POSITION.app : FAB_BOTTOM_POSITION.web
  } right-[calc((100%_-_768px)_/_2_+_28px)] tablet:right-[24px]`,
  'bottom-center': `${
    isApp ? FAB_BOTTOM_POSITION.app : FAB_BOTTOM_POSITION.web
  } left-1/2 transform -translate-x-1/2`,
}
