import { z } from 'zod'
import { KOR_CANCERS, KOR_LIFE_STYLE_TYPE } from '@/consts'
import { cancerKorFilterSchema } from '@/types/cancer'

export const Cancer = z.enum(['전체', ...KOR_CANCERS])
export const Category = z.enum([KOR_LIFE_STYLE_TYPE, ...KOR_CANCERS])
export const ContentTypeSchema = z.enum(['QNA', 'GUIDE'])

// category : type이 GUIDE인 경우 5대암종 혹은 생활백서가 들어감
// type이 QNA인 경우 무조건 null
export const contentSchema = z.object({
  cancer: cancerKorFilterSchema,
  category: Category.nullable(),
  type: ContentTypeSchema,
  id: z.number(),
  parent_id: z.number().nullable(),
  content: z.string(),
  tags: z.array(z.string()),
})

export type ContentType = z.infer<typeof ContentTypeSchema>
export type CancerType = z.infer<typeof cancerKorFilterSchema>
export type CategoryType = z.infer<typeof Category>
export type ContentResponse = z.infer<typeof contentSchema>
