/**
 * 컨텐츠의 태그들을 최대 한 줄로 노출하는 컴포넌트
 * @param tags 태그 배열
 */
const Tags = ({ tags }: { tags: string[] }) => {
  return (
    <div className="h-6 mt-3 overflow-hidden">
      {tags.map((tag, index) => (
        <span
          key={index}
          className="inline-block text-grey-400 mr-3 last:mr-0 prose-p3"
        >
          #{tag}
        </span>
      ))}
    </div>
  )
}

export default Tags
