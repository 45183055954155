import { PropsWithChildren, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { v4 as uuidv4 } from 'uuid'
import MaterialSymbol from '@/v1/MaterialSymbol'
const colors = require('@/v1/colors')

const MIN_WIDTH = 200
const ITEM_HEIGHT = 40
const ACTIVE_TEXT_STYLE = { fontWeight: 700, color: colors.lavendar[700] }

const UUID = uuidv4()
const BUTTON_ID = `dropdown-button-${UUID}`
const MENU_ID = `dropdown-menu-${UUID}`

interface Props {
  options: string[]
  menuMarginTop?: number // 드롭다운 버튼과 메뉴 간 마진
  value?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  gaToggleSelector?: string // 드롭다운 메뉴를 토글하여 펼치는 버튼 영역
  gaDropdownSelector?: string // 펼쳐진 드롭다운 메뉴의 각 아이템
}

const Dropdown = ({
  options,
  value,
  onClick,
  menuMarginTop = 0,
  gaToggleSelector,
  gaDropdownSelector,
  children,
}: PropsWithChildren<Props>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event)
    setAnchorEl(null)
  }

  return (
    <div>
      <button
        id={BUTTON_ID}
        aria-controls={open ? MENU_ID : undefined}
        aria-expanded={open}
        aria-haspopup="true"
        onClick={handleOpen}
        data-ga={gaToggleSelector}
        suppressHydrationWarning
      >
        {children}
      </button>
      <Menu
        id={MENU_ID}
        MenuListProps={{ 'aria-labelledby': BUTTON_ID }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              marginTop: menuMarginTop,
              minWidth: MIN_WIDTH,
              maxHeight: ITEM_HEIGHT * 6.5,
              borderRadius: '12px',
              boxShadow: '0px 6px 16px 0px rgba(40, 45, 50, 0.20)',
            },
            sx: {
              '& .MuiList-root': {
                padding: 0,
              },
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            aria-label={option}
            selected={option === value}
            onClick={handleClick}
            className="[&>*]:pointer-events-none"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 24px',
              '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
                ...ACTIVE_TEXT_STYLE,
                backgroundColor: colors.lavendar[100],
              },
              '&:hover': {
                ...ACTIVE_TEXT_STYLE,
                backgroundColor: 'transparent',
              },
            }}
            data-ga={gaDropdownSelector}
          >
            {option}
            {option === value && (
              <MaterialSymbol name="check" className="fill-lavendar-700" />
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default Dropdown
