import useSWR from 'swr'
import { Statuses } from '@/consts'
import useAuth from '@/hooks/useAuth'
import type { HealthInformation } from '@/hooks/useMyHealthInfo'
import type { Interest, MyInterests } from '@/types/interest'
import { getMajorCancerType } from '@/utils/recommend'
import useMyHealthInfo from '../useMyHealthInfo'

/**
 * 내 계정정보 정보의 cancer 및 status 값을 기반으로 추천 암종 및 치료단계 설정
 * @param myHealthInfo
 * @returns cancerType, status 추천 암종 및 치료단계
 */
const getMyCancerTypeStatus = (myHealthInfo: HealthInformation | undefined) => {
  return {
    cancerType: getMajorCancerType(myHealthInfo?.cancer),
    status: myHealthInfo?.status ?? Statuses.예방,
  }
}

/**
 * 내 계정정보 정보의 관심사 값을 기반으로 추천 관심사 설정
 * @param interests 전체 관심사 목록. { key: 영문명, value: 한글명 }
 * @param myInterest 내 관심사 정보. { data: 관심사 한글명 배열 }
 * @returns 관심사 영문명 배열
 */
const getMyInterest = (
  interests: Interest[],
  myInterest: MyInterests | undefined,
) => {
  return interests
    .filter((interest) => myInterest?.data.includes(interest.value))
    .map((item) => item.key)
}

/**
 * 내 계정정보 정보를 기반으로 추천 암종, 치료단계, 관심사 반환
 * 이 값을 기반으로 추천 컨텐츠를 가져온다.
 * @returns 추천 암종, 치료단계, 관심사
 */
const useCancerContentsParam = () => {
  const { isLoggedIn } = useAuth()
  const { myHealthInfo } = useMyHealthInfo()
  const { data: interests } = useSWR<Interest[]>('data/interest/')
  const { data: myInterest } = useSWR<MyInterests>(
    isLoggedIn ? 'me/interest/' : null,
  )

  const interestParam = new URLSearchParams()
  const { cancerType, status } = getMyCancerTypeStatus(myHealthInfo)
  const interestkeys = getMyInterest(interests || [], myInterest)

  //예시 interest%5B%5D=SYMPTOM_OR_SIDE_EFFECT&interest%5B%5D=SUPPORT_OR_POLICY 로 적용
  interestkeys.forEach((key) => interestParam.append('interest[]', key))

  if (!myHealthInfo || !interests || !myInterest) return null
  return {
    cancerType,
    status,
    interestkeys: interestParam.toString(),
  }
}

export default useCancerContentsParam
