import { FAB_BOTTOM_POSITION } from '@/consts'
import { isWebview } from '@/utils'
import type { FAButtonStyleByVariant } from '@/v1/FAButton/types'

const isApp = isWebview()

export const faButtonLayoutStyle =
  'fixed flex justify-center items-center flex-nowrap cursor-pointer disabled:cursor-not-allowed shadow-[0_6px_16px_rgba(40,45,50,0.2)] whitespace-nowrap overflow-hidden transition-[width] z-10'
export const faButtonLayoutGapStyles = ['gap-[4px]', 'gap-[8px]']

export const faButtonStyleByVariant: FAButtonStyleByVariant = {
  extended: {
    size: {
      large:
        'min-w-[180px] h-[64px] rounded-[32px] text-[18px] [&_svg]:w-[32px] [&_svg]:h-[32px]',
      medium:
        'min-w-[180px] h-[56px] rounded-[32px] text-[16px] [&_svg]:w-[24px] [&_svg]:h-[24px]',
    },
    color: {
      primary:
        'text-white bg-lavendar-500 hover:bg-lavendar-700 [&_svg]:fill-white',
      secondary:
        'text-lavendar-500 bg-lavendar-100 hover:text-lavendar-700 hover:bg-lavendar-200 [&_svg]:fill-lavendar-500',
      tertiary:
        'text-grey-900 bg-white hover:text-lavendar-500 hover:bg-white [&_svg]:fill-grey-900 [&_svg]:hover:fill-lavendar-500',
    },
    rounded: 'rounded-[28px]',
  },
  regular: {
    size: {
      large:
        'w-[64px] h-[64px] rounded-[32px] [&_svg]:w-[32px] [&_svg]:h-[32px]',
      medium:
        'w-[56px] h-[56px] rounded-[32px] [&_svg]:w-[24px] [&_svg]:h-[24px]',
    },
    color: {
      primary:
        'text-white bg-lavendar-500 hover:bg-lavendar-700 [&_svg]:fill-white',
      secondary:
        'text-lavendar-500 bg-lavendar-100 hover:text-lavendar-700 hover:bg-lavendar-200 [&_svg]:fill-lavendar-500',
      tertiary:
        'text-grey-900 bg-white hover:text-lavendar-500 hover:bg-white [&_svg]:fill-grey-900  [&_svg]:hover:fill-lavendar-500',
    },
    rounded: '',
  },
}

export const faButtonPositionStyle = {
  'bottom-right': `${
    isApp ? FAB_BOTTOM_POSITION.app : FAB_BOTTOM_POSITION.web
  } right-[calc((100%_-_768px)_/_2_+_28px)] tablet:right-[24px]`,
  'bottom-center': `${
    isApp ? FAB_BOTTOM_POSITION.app : FAB_BOTTOM_POSITION.web
  } left-1/2 transform -translate-x-1/2`,
}
