module.exports = {
  black: '#000000',
  white: '#FFFFFF',
  lavendar: {
    // old
    0: '#F4F2FF',
    10: '#B8AFF2',
    20: '#968BF2',
    30: '#7269F0',
    40: '#4A44EB',
    50: '#2E2EDD',
    // new
    100: '#EEECFF',
    200: '#CAC6FE',
    300: '#A6A0FD',
    400: '#8279FC',
    500: '#5E53FB',
    600: '#493FDE',
    700: '#352BC1',
    800: '#2017A4',
    900: '#0B0387',
  },
  aqua: {
    // new
    100: '#E3F7FB',
    200: '#AAEBF7',
    300: '#72E0F3',
    400: '#39D5EE',
    500: '#00C9EA',
    600: '#02A9D6',
    700: '#0388C3',
    800: '#0568AF',
    900: '#003C73',
  },
  coral: {
    // old
    0: '#FFF1F3',
    10: '#FFDBC6',
    20: '#FFB3A3',
    30: '#FF9E91',
    40: '#FF7D7D',
    50: '#FF6E65',
    // new
    100: '#FEEEEA',
    200: '#FCCFC5',
    300: '#FAB0A0',
    400: '#F7917B',
    500: '#F57256',
    600: '#DF5A41',
    700: '#CA432B',
    800: '#B42B16',
    900: '#9E1300',
  },
  grey: {
    // new
    100: '#F2F3F5',
    200: '#D8DADC',
    300: '#BFC1C3',
    400: '#A6A7AA',
    500: '#8C8E91',
    600: '#75777A',
    700: '#5D6062',
    800: '#46484B', // rgb(70, 72, 75)
    900: '#2E3134', // rgb(46, 49, 52)
  },

  // old
  intestine: '#004ADA',
  // new
  colon: '#004ADA',
  breast: '#BE6EBB',
  stomach: '#8C82FF',
  thyroid: '#42BDC5',
  lung: '#C1B2A4',

  // new
  kakao: {
    yellow: '#FEE500',
    brown: '#191600',
  },
  naver: {
    green: '#03C75A',
  },
  facebook: {
    blue: '#1877F2',
  },
  google: {
    blue: '#4285F4',
  },
  apple: {
    black: '#000000',
  },

  green: {
    // old
    positive: '#B4EFC4',
    point: '#42BE65',
    naver: '#03C75A',
    // new
    100: '#EAFAEA',
    500: '#32A675',
    700: '#29896A',
  },
  orange: {
    // new
    100: '#FFF9F2',
    500: '#F58349',
    700: '#C25E35',
  },
  red: {
    // old
    negative: '#FA4D56',
    // new
    100: '#FFF3F4',
    500: '#F06565',
    700: '#B84E4E',
  },
  link: {
    // new
    blue: {
      100: '#F0F3FF',
      500: '#556FF2',
      700: '#3D52B9',
    },
  },

  // old
  gray: {
    10: '#F8F9FA',
    20: '#F2F4F6',
    30: '#EAECEE',
    40: '#DFE2E5',
    50: '#CED4DA',
    60: '#ABB4BD',
    70: '#9199A1',
    80: '#5F676F',
    90: '#414951',
    100: '#282D32',
  },
  blue: {
    0: '#F4F8FF',
    25: '#EBF2FF',
    50: '#B2CDFF',
    75: '#6590ff',
    100: '#2464E6',
  },
  yellow: {
    kakao: '#FEE500',
    point: '#FFC224',
  },
  brown: {
    kakao: '#191600',
  },
  teal: '#00C9EA',
  cyan: {
    0: '#F2FCFF',
    10: '#70EEFF',
    20: '#38DDFA',
    30: '#16CAF3',
    40: '#12ADD8',
    50: '#148FB8',
  },
}
