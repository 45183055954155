import { KOR_CANCER_TYPES, KOR_LIFE_STYLE_TYPE, URL_PATH } from '@/consts'
import type { CancerObject } from '@/types/cancer'
import { cancerKorFilterSchema } from '@/types/cancer'
import type { ContentType, CategoryType } from '@/types/recommend'
import { encodeTextWithoutSpace } from '@/utils/guide'

export type GetLinkProps = {
  id: string
  parent_id: string | null
  type: ContentType
  category: CategoryType | null
  content: string
}

export const CANCER_TO_GUIDES_PATH = {
  [KOR_CANCER_TYPES.breast]: URL_PATH.GuidesBreast,
  [KOR_CANCER_TYPES.lung]: URL_PATH.GuidesLung,
  [KOR_CANCER_TYPES.colon]: URL_PATH.GuidesColon,
  [KOR_CANCER_TYPES.stomach]: URL_PATH.GuidesStomach,
  [KOR_CANCER_TYPES.thyroid]: URL_PATH.GuidesThyroid,
  [KOR_LIFE_STYLE_TYPE]: URL_PATH.GuidesLife,
} as const

/**
 * 추천 컨텐츠로 이동한다.
 * @param id 소분류 ID
 * @param parent_id 중분류 ID. 소분류인 경우에만 parent_id 값이 있고, 중분류는 null이다.
 * @param type 컨텐츠 타입
 * @param cancer 암 종류
 * @param content 제목
 * @returns 링크
 */
export const getLink = ({
  id,
  parent_id,
  type,
  category,
  content,
}: GetLinkProps) => {
  // 주제별 암 정보
  if (type === 'GUIDE') {
    const subCategory =
      parent_id === null ? '' : `#${encodeTextWithoutSpace(content)}`
    // type이 GUIDE인 경우 category 값은 5대암종 혹은 생활백서로 보장된다.
    return `${CANCER_TO_GUIDES_PATH[category!]}/${
      parent_id ?? id
    }${subCategory}`
  }
  // 질문과 답변
  return `/boards/${id}`
}

export const getValidCancerType = (cancerName: string) => {
  try {
    return cancerKorFilterSchema.parse(cancerName)
  } catch (error) {
    return '전체'
  }
}

/**
 * 부모암이 5대 암종인 경우 부모암으로 반환하고, 그렇지 않은 경우 "전체"를 반환한다.
 * @param cancer 객체 형태의 암종
 * @returns 5대 암종 값 반환 ( "전체" | "유방암" | "갑상선암" | "폐암" | "위암" | "대장암" )
 */
export const getMajorCancerType = (cancer?: CancerObject | null) => {
  // 내 암 정보가 5대 암종이면 내 암 정보로 설정
  if (cancer?.is_major) return getValidCancerType(cancer.name)
  // 내 부모 암 정보가 있으면 부모암 정보로 설정
  if (cancer?.parent) return getValidCancerType(cancer.parent)
  return '전체'
}
