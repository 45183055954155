import { Children, createContext, useMemo } from 'react'
import type { PropsWithChildren } from 'react'
import clsx from 'clsx'
import {
  faButtonGroupItemStyle,
  faButtonGroupLayoutStyle,
  faButtonGroupStyle,
  faButtonGroupPositionStyle,
} from '@/v1/FAButtonGroup/styles'
import type { FAButtonGroupProps } from '@/v1/FAButtonGroup/types'

type FAButtonGroupContextValue = {
  classNames?: string
}

export const FAButtonGroupContext =
  createContext<FAButtonGroupContextValue | null>(null)

const FAButtonGroup = ({
  size = 'large',
  color = 'primary',
  rounded: isRounded,
  position = 'bottom-right',
  children,
}: PropsWithChildren<FAButtonGroupProps>) => {
  const contextValue: FAButtonGroupContextValue = useMemo(
    () => ({
      classNames: clsx(
        faButtonGroupItemStyle.layout,
        faButtonGroupItemStyle.color[color],
      ),
    }),
    [color],
  )

  return (
    <div
      className={clsx(
        faButtonGroupLayoutStyle,
        faButtonGroupStyle.size[size],
        faButtonGroupStyle.color[color],
        { [faButtonGroupStyle.rounded]: isRounded },
        faButtonGroupPositionStyle[position],
      )}
    >
      <FAButtonGroupContext.Provider value={contextValue}>
        {Children.map(children, (child, index) => (
          <>
            {index > 0 && (
              <span className={clsx(faButtonGroupStyle.divider[color])} />
            )}
            {child}
          </>
        ))}
      </FAButtonGroupContext.Provider>
    </div>
  )
}

export default FAButtonGroup
