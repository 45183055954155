import { PropsWithChildren, ReactElement, createElement } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { filterPropsWithPrefix } from '@/utils/filterPropsWithPrefix'
import { ChevronRightIcon } from '@/v1/Icons'

const TEXT_ACTIVE_COLOR =
  'group-hover/item:text-lavendar-500 group-[.is-selected]/item:text-lavendar-700'
const ICON_ACTIVE_COLOR =
  'group-hover/item:fill-lavendar-500 group-[.is-selected]/item:fill-lavendar-700'
const LIST_PADDING_CONFIG = {
  px: {
    sm: 'px-0',
    md: 'px-1',
  },
  py: {
    sm: 'py-3',
    md: 'py-4',
  },
}

// 리스트 아이템 버튼과 리스트 아이템 액션에서 공통으로 사용한다.
const LIST_ITEM_BUTTON_CLASSNAME = [
  'flex',
  'flex-row',
  'w-full',
  'relative',
  'cursor-pointer',
  'group/item',
  'text-start',
  'items-center',
].join(' ')

interface IconProps {
  size?: number
  className?: string
}

type ListItemActionProps = {
  arrow?: boolean
  px?: 'sm' | 'md'
  py?: 'sm' | 'md'
} & (
  | {
      hover: true
      selected?: boolean
    }
  | {
      hover?: false
      selected?: never
    }
)

type ListItemButtonProps = React.ComponentPropsWithoutRef<'button'> &
  ListItemActionProps & {
    icon?: ReactElement<IconProps>
  }

type ListItemLinkProps = React.ComponentPropsWithoutRef<'a'> &
  ListItemActionProps & {
    href: string
    icon?: ReactElement<IconProps>
  }

/**
 * 리스트 컴포넌트. 리스트 아이템은 List.ItemButton 또는 List.ItemLink를 사용한다.
 * @param divider - 리스트 아이템 사이에 구분선을 넣을지 여부
 */
const List = ({
  divider = false,
  children,
}: PropsWithChildren<{
  divider?: boolean
}>) => {
  return (
    <div
      className={clsx('bg-white', 'group/list', {
        divider,
      })}
    >
      {children}
    </div>
  )
}

/**
 * ListItemButton 또는 ListItemLink 안에 들어가는 컴포넌트
 * @param arrow - 오른쪽 화살표를 표시할지 여부
 * @param hover - 리스트 아이템에 마우스를 올렸을 때 텍스트/아이콘/배경색을 바꿀지 여부
 * @param icon - 왼쪽에 표시할 아이콘
 * @param children - 리스트 아이템의 내용
 */
const ListItemActionContent = ({
  arrow = false,
  hover = false,
  icon,
  children,
}: {
  arrow?: boolean
  hover?: boolean
  icon?: ReactElement<IconProps>
  children: React.ReactNode
}) => {
  return (
    <>
      {icon && (
        <span className="mr-4">
          {createElement(icon.type, {
            ...icon.props,
            size: 24,
            className: hover && ICON_ACTIVE_COLOR,
          })}
        </span>
      )}

      <span className={`flex-1 ${hover ? TEXT_ACTIVE_COLOR : ''}`}>
        {children}
      </span>
      {arrow && (
        <span className={clsx('flex', 'ml-4')}>
          <ChevronRightIcon className={hover ? ICON_ACTIVE_COLOR : ''} />
        </span>
      )}
    </>
  )
}

/**
 * 리스트 아이템 버튼
 * @param onClick - 리스트 아이템을 클릭했을 때 실행할 함수
 * @param hover - 리스트 아이템에 마우스를 올렸을 때 텍스트/아이콘/배경색을 바꿀지 여부
 * @param selected - 리스트 아이템이 선택되었는지 여부. hover가 true일 때만 사용한다.
 * @param arrow - 오른쪽 화살표를 표시할지 여부
 * @param icon - 왼쪽에 표시할 아이콘
 * @param children - 리스트 아이템의 내용
 * @param gaSelector - 리스트 아이템을 클릭했을 때 GA에 전송할 selector
 * @param rest - button 태그에 추가할 속성
 */
const ListItemButton = ({
  selected = false,
  arrow = false,
  hover = false,
  icon,
  children,
  onClick,
  px = 'md',
  py = 'md',
  ...rest
}: PropsWithChildren<ListItemButtonProps>) => {
  const dataProps = filterPropsWithPrefix(rest, 'data-')
  const { className, disabled } = rest

  return (
    <button
      className={twMerge(
        clsx(
          LIST_ITEM_BUTTON_CLASSNAME,
          LIST_PADDING_CONFIG['px'][px],
          LIST_PADDING_CONFIG['py'][py],
          // TODO: 이거 더 나은 방법?
          'group-[.divider]/list:border-grey-100',
          'group-[.divider]/list:border-b',
          'group-[.divider]/list:last:border-b-0',
          'group-[.divider]/list:only:border-b-0',
          {
            'is-selected bg-lavendar-100 text-lavendar-30': selected && !!hover,
          },
          'disabled:cursor-default',
          className,
        ),
      )}
      disabled={disabled}
      onClick={onClick}
      {...dataProps}
    >
      <ListItemActionContent arrow={arrow} icon={icon} hover={hover}>
        {children}
      </ListItemActionContent>
    </button>
  )
}

/**
 * 리스트 아이템 링크
 * @param href - a 태그의 href 속성
 * @param hover - 리스트 아이템에 마우스를 올렸을 때 텍스트/아이콘/배경색을 바꿀지 여부
 * @param selected - 리스트 아이템이 선택되었는지 여부. hover가 true일 때만 사용한다.
 * @param arrow - 오른쪽 화살표를 표시할지 여부
 * @param icon - 왼쪽에 표시할 아이콘
 * @param children - 리스트 아이템의 내용
 * @param gaSelector - 리스트 아이템을 클릭했을 때 GA에 전송할 selector
 * @param rest - a 태그에 추가할 속성
 */
const ListItemLink = ({
  selected = false,
  arrow = false,
  hover = false,
  href,
  icon,
  children,
  px = 'md',
  py = 'md',
  ...rest
}: PropsWithChildren<ListItemLinkProps>) => {
  const dataProps = filterPropsWithPrefix(rest, 'data-')
  return (
    <Link
      className={clsx(
        LIST_ITEM_BUTTON_CLASSNAME,
        LIST_PADDING_CONFIG['px'][px],
        LIST_PADDING_CONFIG['py'][py],
        'border-grey-100',
        'group-[.divide-y]/list:border-grey-100 border-b',
        'last:border-b-0',
        {
          'is-selected bg-lavendar-100 text-lavendar-30': selected && !!hover,
        },
      )}
      href={href}
      as={href}
      {...dataProps}
    >
      <ListItemActionContent arrow={arrow} icon={icon} hover={hover}>
        {children}
      </ListItemActionContent>
    </Link>
  )
}

List.ItemButton = ListItemButton
List.ItemLink = ListItemLink
export default List
